/* Loader Body */
.loader_body {
  background: #1a1a1a; /* Dark background color */
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Loader Plinth Image */
.loader_plinth_img {
  height: 12rem;
  width: 18rem;
  animation:  scale 1.5s infinite,fadeInOut 1.8s alternate infinite;
  cursor: none;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}