.HandleContainer{
    position: fixed;
    bottom: 8.5%;
    right: 0.3%;
    background-color: transparent;
    border-right: 2px solid ;
    border-image-source: linear-gradient(to bottom, #F07217 0%, #C82222 29.69%, #259ABF 55.73%, #7B1FA7 77.08%);
    border-image-slice: 1;
    display: flex;
    flex-direction: column;
    padding: 0.5%;
    z-index: 40;
}
.HeadTop{
    position: fixed;
    top : 5px;
    display: none;
}
.Handles{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.3vw;
    width: 100%;
    height: 100%;

}
.Youtube{
    margin-top: 0%;
}
.yt{
    width: 2.06vw;
    height: auto;
    transition: transform 0.3s ease-in-out 0.01s;
}
.yt:hover{
    transform: scale(1.2);
  }
.insta{
    width: 1.7vw;
    height: auto;
    transition: transform 0.3s ease-in-out 0.01s;
}
.insta:hover{
    transform: scale(1.2);
  }
.facebook{
    width: 2.15vw;
    height: auto;
    transition: transform 0.3s ease-in-out 0.01s;
}
.facebook:hover{
    transform: scale(1.2);
  }
.twitter{
    width: 2.01vw;
    height: auto;
    transition: transform 0.3s ease-in-out 0.01s;
}
.twitter:hover{
    transform: scale(1.2);
  }

@media (max-width:600px) {
    .Handles{
        margin-top: 2.5vh;
    }
    .yt{
       width: 5vw;
       margin-top: 0;
    }
    .Youtube{
        margin-top: 0%;
    }
    .twitter{
       width: 5vw;
    }
    .facebook{
       width: 5vw;
    }
    .insta{
       width: 5vw;
    }
}
