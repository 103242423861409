.timeline_container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 40px 0;
}
/* dotted line */
.timeline_container::after {
    content: '';
    position: absolute;
    left: calc(50% - 2px);
    width: 2px;
    height: 90%;
    margin-top: 12%;
    border-left: 2px dotted #fff;
}

.timelineItem {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    padding-left: 30px;
    position: relative;
    margin: 10px 0;
    width: 50%;
}

.timelineItem:nth-child(odd) {
    display: flex;
    align-self: flex-end;
    justify-content: flex-start;
    position: relative;
    padding-left: 20px;
    padding-right: 30px;
}

.timelineitem_content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px;
    position: relative;
    text-align: right;
}

.timelineitem_content:nth-child(even) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    position: relative;
    text-align: right;
    
}

.timelineItem:nth-child(odd) .timelineitem_content {
    text-align: left;
    align-items: flex-start;
}
.timelineItem:nth-child(odd) .timelineitem_content::after {
    right: auto;
    left: -7.5px;
}

.timelineitem_content .circle {
    background-color: #fff;
    border: 3px solid #e17b77;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 10px);
    right: -40px;
    width: 20px;
    height: 20px;
    z-index: 100;
}

.timelineItem:nth-child(odd) .timelineitem_content.circle {
    right: auto;
    left: -40px;
}

.circle {
    background-color: transparent; 
    border: 3px solid transparent; 
    border-radius: 50%;
    position: absolute;
    top: 15%;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    z-index: 100;
    box-shadow: 0 0 0 2px gainsboro; 
    display: flex;
    justify-content: center;
    align-items: center;
    left: -19px;
}

.innerCircle {
    width: 12px; 
    height: 12px; 
    background-color: #fff;
    border-radius: 50%;
}   

.timelineItem:nth-child(even) .circle {
    left: auto;
    right: -17px;
}

.timelineItem:nth-child(even) .itemlist {
    flex-direction: row-reverse;
}

@media only screen and (max-width: 1023px) {
    .timelineitem_content {
        max-width: 100%;
    }
}


@media only screen and (max-width: 767px) {
    /* dotted line */
    .timeline_container::after {
        content: '';
        position: absolute;
        left: calc(16% - 2px);
        width: 2px;
        height: 90%;
        margin-top: 28%;
        border-left: 2px dotted #fff;
    }
    .timelineitem_content,
    .timelineItem:nth-child(odd).timelineitem_content{
        padding: 0px 0px;
        text-align: start;
        align-items: start;
    }
    .timelineItem{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        text-align: right;
        width: 100%;
    }
    .timelineItem:nth-child(even) {
        display: flex;
        align-self: flex-end;
        justify-content: flex-start;
        position: relative;
        padding-left: 30px;
        padding-right: 0px;
    }
    .timelineItem:nth-child(odd) {
        display: flex;
        align-self: flex-end;
        justify-content: flex-start;
        position: relative;
        padding-left: 30px;
        padding-right: 0px;
    }
    
    .timelineitem_content{
        text-align: start;
        padding-left: 52px;
        padding-right: 16px;
        
    }
    .timelineItem:nth-child(even) .circle {
        left: 42px;
    }
    .timelineItem:nth-child(odd) .circle {
        left: 42px;
    }

    .circle {
        background-color: transparent; 
        border: 3px solid transparent; 
        border-radius: 50%;
        position: absolute;
        top: 15%;
        transform: translateY(-50%);
        width: 28px;
        height: 28px;
        z-index: 100;
        box-shadow: 0 0 0 2px gainsboro; 
        display: flex;
        justify-content: center;
        align-items: center;
        left: -19px;
    }
    
    .innerCircle {
        width: 12px; 
        height: 12px; 
        background-color: #fff;
        border-radius: 50%;
    }   
    .timelineitem_content {
        width: 100%;
    }
    .timelineItem{
        display: flex;
        align-self: flex-start;
        justify-content: flex-start;
        position: relative;
    }
    .timelineItem:nth-child(even) .itemlist {
        flex-direction: row;
    }
    .timeline_container {
        display: flex;
        flex-direction: column;
        position: relative;
    }
    
}