body{
    background-color: #020422;
}

headfont, h1{
    font-family: Dark;
    background-clip: text;
    color: white;
}
.faq-container {
    backdrop-filter: blur(10px); /* Apply a blur effect */
  }
  